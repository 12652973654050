import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import FSPanel from './fsPanel';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element = {<App />} />
      <Route path = "/IceStationZebra" element = {<FSPanel></FSPanel>}></Route>
    </Routes>
  </BrowserRouter>
);

const firebaseConfig = {
  apiKey: "AIzaSyDPyhOeW3HF6_kxwRSNkegpjXUsY-giiZw",
  authDomain: "erauflightline.firebaseapp.com",
  databaseURL: "https://erauflightline.firebaseio.com",
  projectId: "erauflightline",
  storageBucket: "erauflightline.appspot.com",
  messagingSenderId: "112470751843",
  appId: "1:112470751843:web:9e7c1d518e9979c897d656",
  measurementId: "G-KFTNWBKJX7"
};

firebase.initializeApp(firebaseConfig)
//export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const db = firebase.firestore();
document.body.style = 'background: #282c34;';



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
