import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Divider from '@mui/material/Divider';
class ShuttleDisplay extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            campusShuttleOneSchedule : "On Call",
            campusShuttleOneColor : "N/A",
            westRampShuttleOneSchedule : "On Call",
            westRampShuttleOneColor : "N/A",
            campusShuttleTwoSchedule : "On Call",
            campusShuttleTwoColor : "N/A",
            westRampShuttleTwoSchedule : "On Call",
            westRampShuttleTwoColor : "N/A"
        }
        this.onChange = this.onChange.bind(this)
    }

    componentDidMount(){
        let a = this
        let db = firebase.firestore()
        let shuttleRef = db.collection("shuttleOps")
        shuttleRef.get().then(function(querySnapshot){
            querySnapshot.forEach(function(doc){
                let scheduleRef = doc.id + "Schedule"
                let colorRef = doc.id + "Color"
                a.setState({[scheduleRef] : doc.data().frequency, [colorRef] : doc.data().color})
            })
        })

        db.collection('shuttleOps').doc('campusShuttleOne').onSnapshot((doc)=>{
            a.setState({campusShuttleOneSchedule : doc.data().frequency, campusShuttleOneColor : doc.data().color})
        })
        db.collection('shuttleOps').doc('campusShuttleTwo').onSnapshot((doc)=>{
            a.setState({campusShuttleTwoSchedule : doc.data().frequency, campusShuttleTwoColor : doc.data().color})
        })
        db.collection('shuttleOps').doc('westRampShuttleOne').onSnapshot((doc)=>{
            a.setState({westRampShuttleOneSchedule : doc.data().frequency, westRampShuttleOneColor : doc.data().color})
        })
        db.collection('shuttleOps').doc('westRampShuttleTwo').onSnapshot((doc)=>{
            a.setState({westRampShuttleTwoSchedule : doc.data().frequency, westRampShuttleTwoColor : doc.data().color})
        })


    }
    onChange(event){
        let a = this
        console.log(event.target.name)
        this.setState({[event.target.name] : event.target.value}, ()=>{
            let db = firebase.firestore()
            if(event.target.name == "campusShuttleOneSchedule" || event.target.name == "campusShuttleOneColor"){
                db.collection("shuttleOps").doc("campusShuttleOne").set({
                    color : a.state.campusShuttleOneColor,
                    frequency : a.state.campusShuttleOneSchedule
                })
            }
            if(event.target.name == "westRampShuttleOneSchedule" || event.target.name == "westRampShuttleOneColor"){
                db.collection("shuttleOps").doc("westRampShuttleOne").set({
                    color : a.state.westRampShuttleOneColor,
                    frequency : a.state.westRampShuttleOneSchedule
                })
            }
            if(event.target.name == "campusShuttleTwoSchedule" || event.target.name == "campusShuttleTwoColor"){
                db.collection("shuttleOps").doc("campusShuttleTwo").set({
                    color : a.state.campusShuttleTwoColor,
                    frequency : a.state.campusShuttleTwoSchedule
                })
            }
            if(event.target.name == "westRampShuttleTwoSchedule" || event.target.name == "westRampShuttleTwoColor"){
                db.collection("shuttleOps").doc("westRampShuttleTwo").set({
                    color : a.state.westRampShuttleTwoColor,
                    frequency : a.state.westRampShuttleTwoSchedule
                })
            }

        })
       
    }
    render(){
        return(
            <Card style = {{width : '90%', marginTop : '2%', marginLeft : '5%',}} variant="outlined">
            <Typography variant="h4" component="div">
              Shuttle Status
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs = {4}>
                    <Typography variant="h5" component="div">
                      Campus Shuttle 1 : 
                    </Typography>
                  </Grid>
                  <Grid item xs = {4}>
                    <Select disabled labelId="campus-shuttle-1-frequency" id="campus-shuttle-1-frequency" name = "campusShuttleOneSchedule" value={this.state.campusShuttleOneSchedule} label="Freq" onChange = {this.onChange}>
                      <MenuItem value={"00/30"}>00/30</MenuItem>
                      <MenuItem value={"15/45"}>15/45</MenuItem>
                      <MenuItem value={"On Call"}>On Call</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs = {4}>
                    <Select disabled labelId="campus-shuttle-1-color" id="campus-shuttle-1-color" name = "campusShuttleOneColor" value={this.state.campusShuttleOneColor} label="Color" onChange = {this.onChange}>
                      <MenuItem value={"Red"}>Red</MenuItem>
                      <MenuItem value={"Blue"}>Blue</MenuItem>
                      <MenuItem value={"Green"}>Green</MenuItem>
                      <MenuItem value={"N/A"}>N/A</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs = {4}>
                    <Typography variant="h5" component="div">
                      Campus Shuttle 2 : 
                    </Typography>
                  </Grid>
                  <Grid item xs = {4}>
                    <Select disabled labelId="campus-shuttle-2-frequency" id="campus-shuttle-2-frequency" name = "campusShuttleTwoSchedule" value={this.state.campusShuttleTwoSchedule} label="Freq" onChange = {this.onChange}>
                      <MenuItem value={"00/30"}>00/30</MenuItem>
                      <MenuItem value={"15/45"}>15/45</MenuItem>
                      <MenuItem value={"On Call"}>On Call</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs = {4}>
                    <Select disabled labelId="campus-shuttle-2-color" id="campus-shuttle-2-color" name = "campusShuttleTwoColor" value={this.state.campusShuttleTwoColor} label="Color" onChange = {this.onChange}>
                      <MenuItem value={"Red"}>Red</MenuItem>
                      <MenuItem value={"Blue"}>Blue</MenuItem>
                      <MenuItem value={"Green"}>Green</MenuItem>
                      <MenuItem value={"N/A"}>N/A</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs = {4}>
                    <Typography variant="h5" component="div">
                      West Ramp Shuttle 1 : 
                    </Typography>
                  </Grid>
                  <Grid item xs = {4}>
                    <Select disabled labelId="wr-shuttle-1-frequency" id="wr-shuttle-1-frequency" name = "westRampShuttleOneSchedule" value={this.state.westRampShuttleOneSchedule} label="Freq" onChange = {this.onChange}>
                        <MenuItem value={"18/48"}>18/48</MenuItem>
                      <MenuItem value={"06/30"}>06/30</MenuItem>
                      <MenuItem value={"On Call"}>On Call</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs = {4}>
                    <Select disabled labelId="wr-shuttle-1-color" id="wr-shuttle-1-color" name = "westRampShuttleOneColor" value={this.state.westRampShuttleOneColor} label="Color" onChange = {this.onChange}>
                      <MenuItem value={"Red"}>Red</MenuItem>
                      <MenuItem value={"Blue"}>Blue</MenuItem>
                      <MenuItem value={"Green"}>Green</MenuItem>
                      <MenuItem value={"N/A"}>N/A</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            
              <Divider />
             
            
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs = {4}>
                    <Typography variant="h5" component="div">
                      West Ramp Shuttle 2 : 
                    </Typography>
                  </Grid>
                  <Grid item xs = {4}>
                    <Select disabled labelId="wr-shuttle-2-frequency" id="wr-shuttle-2-frequency" name = "westRampShuttleTwoSchedule" value={this.state.westRampShuttleTwoSchedule} label="Freq" onChange = {this.onChange}>
                      <MenuItem value={"18/48"}>18/48</MenuItem>
                      <MenuItem value={"06/30"}>06/30</MenuItem>
                      <MenuItem value={"On Call"}>On Call</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs = {4}>
                    <Select disabled labelId="wr-shuttle-2-color" id="wr-shuttle-2-color" name = "westRampShuttleTwoColor" value={this.state.westRampShuttleTwoColor} label="Color" onChange = {this.onChange}>
                      <MenuItem value={"Red"}>Red</MenuItem>
                      <MenuItem value={"Blue"}>Blue</MenuItem>
                      <MenuItem value={"Green"}>Green</MenuItem>
                      <MenuItem value={"N/A"}>N/A</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            
            
            </Grid>
            </Card>
        )
    }

}

export default ShuttleDisplay