import React from 'react'
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polyline} from 'google-maps-react';
import Card from '@mui/material/Card'
import PubNub from 'pubnub'
const pubnub = new PubNub({
  publishKey: "pub-c-c64637c2-9a35-4dbe-8c61-38b970b2aa7c",
  subscribeKey: "sub-c-e4d8ec6c-31e6-4a7d-9e69-2ca03e768fc5",
  uuid: "FS",
});
const wrCoords = [
    {lat: 34.648527, lng: -112.418918},
    {lat: 34.647779, lng: -112.417734},
    {lat:34.646962, lng: -112.417079},
    {lat: 34.644936, lng: -112.414874},
    {lat: 34.644429, lng: -112.414552},
    {lat: 34.638737, lng: -112.414508},
    {lat: 34.638469, lng:-112.414585},
    {lat: 34.637053, lng:  -112.415544},
    {lat: 34.636770, lng: -112.415944},
    {lat: 34.636687, lng: -112.416402},
    {lat: 34.636684, lng: -112.417747},
    {lat: 34.636051, lng: -112.417714},
    {lat: 34.635569, lng: -112.417533},
    {lat: 34.632770, lng: -112.428831},
    {lat: 34.632969, lng: -112.429187},
    {lat: 34.647690, lng: -112.433472},
    {lat: 34.648285, lng: -112.432833},
    {lat: 34.651107, lng: -112.430660},
    {lat: 34.652407, lng: -112.429824},
    {lat: 34.653019, lng: -112.429242},
    {lat: 34.654146, lng: -112.427167},
    {lat: 34.652684, lng:  -112.425434},

  ];

  const campusCoords = [
    {lat: 34.648527, lng: -112.418918},
    {lat: 34.647779, lng: -112.417734},
    {lat:34.646962, lng: -112.417079},
    {lat: 34.644936, lng: -112.414874},
    {lat: 34.644429, lng: -112.414552},
    {lat: 34.638737, lng: -112.414508},
    {lat: 34.638469, lng:-112.414585},
    {lat: 34.637053, lng:  -112.415544},
    {lat: 34.636770, lng: -112.415944},
    {lat: 34.636687, lng: -112.416402},
    {lat: 34.636684, lng: -112.417747},
    {lat: 34.636051, lng: -112.417714},
    {lat: 34.635569, lng: -112.417533},
    {lat: 34.632770, lng: -112.428831},
    {lat: 34.632117, lng: -112.431565},
    {lat: 34.631290, lng: -112.432867},
    {lat: 34.630017, lng: -112.434452},
    {lat: 34.629576, lng: -112.436200},
    {lat: 34.629304, lng: -112.437382},
    {lat: 34.629232, lng: -112.439265},
    {lat: 34.619029, lng: -112.443665},
 
  ];
 
class ShuttleMap extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            redShuttleLocaiton : [0,0]
        }
    }
    componentDidMount(){
        let a = this
        pubnub.subscribe({
            channels: [
                "shuttle0",
            ]
        });

        pubnub.addListener({
            message: function(receivedMessage) {
                // handle message
                var locationArray = receivedMessage.message.description.split(":")
                console.log(locationArray)
                a.setState({redShuttleLocaiton : [locationArray[1],locationArray[2]]})
            }
        });
    }
    render(){
        return( 
        <Card style = {{marginLeft : '5%', marginTop : '2%', width : '90%'}}>
            <Map google={this.props.google} zoom={13} style = {{width : '90%', height : '70%'}} initialCenter={{lat: 34.632435,lng: -112.429187}}>
            <Marker name={'redShuttle'} position={{lat:  this.state.redShuttleLocaiton[0], lng: this.state.redShuttleLocaiton[1]}} icon={{url: "https://maps.google.com/mapfiles/ms/icons/red.png"}} />
            
            <Polyline path={wrCoords} strokeColor="#00549F" strokeOpacity={0.8} strokeWeight={4} />
            <Polyline path={campusCoords} strokeColor="#FFC423" strokeOpacity={0.8} strokeWeight={4} />
            
            </Map>
        </Card>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyCQBQlp_kgs4qwuv_lduBQ03qqDVysfyus")
  })(ShuttleMap)

