import logo from './logo.svg';
import './App.css';
import ShuttleStatus from './shuttleStatus';
import ShuttleMap from './shuttleMap';
import ShuttleDisplay from './shuttleDisplay';
function App() {
  return (
    <div className="App" style = {{backgroundColor : '#282c34'}}>
      <div className="header">

      </div>
        <ShuttleDisplay></ShuttleDisplay>
        
        <ShuttleMap></ShuttleMap>
    </div>
  );
}

export default App;
