import ShuttleMap from './shuttleMap';
import ShuttleDisplay from './shuttleDisplay';
import ShuttleStatus from './shuttleStatus'
function FSPanel() {
    return (
      <div className="App" style = {{backgroundColor : '#282c34'}}>
        <div className="header">
  
        </div>
          <ShuttleStatus></ShuttleStatus>
          
          <ShuttleMap></ShuttleMap>
      </div>
    );
  }
  
  export default FSPanel;